const configReducer = (state = {}, action) => {
  Object.freeze(state)
  switch(action.type) {
    case 'RECEIVE_CONFIG':
      return action.config;
    default:
      return state;
  }
};

export default configReducer;

import { connect } from "react-redux";

import Upload from './upload';

const mSTP = (state, ownProps) => {
  return {
  };
};

const mDTP = dispatch => ({
});

export default connect(mSTP, mDTP)(Upload);

import React from 'react';
import ImageUploader from 'react-images-upload';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import { Amplify } from 'aws-amplify';
import { post as amplifyPost } from 'aws-amplify/api';
import './upload.scss'

const awsconfig = {
  aws_project_region: "us-east-2",
  aws_cloud_logic_custom: [
    {
      name: "images",
      endpoint: process.env.REACT_APP_API_ENDPOINT,
      region: "us-east-2",
    },
  ],
};

Amplify.configure(awsconfig);

class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pictures: [],
      imgUpload: "",
      didUpload: false,
      toggleUpload: true,
      completed: false,
      success: null,
      access: false,
    };

    this.onDrop = this.onDrop.bind(this);
    this.submitImage = this.submitImage.bind(this);
    this.resizeImageFile = this.resizeImageFile.bind(this);
    this.resizeImage = this.resizeImage.bind(this);
  }

  componentDidMount() {
    const formToken = this.props.match.params.formToken;

    if (formToken) {
      if (window.sessionStorage.submit) {
        this.setState({
          access: false,
        });
      } else {
        this.setState({
          access: true,
        });
      }
    }
  }

  blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
  }

  resizeImage(image, maxWidth, maxHeight, quality, callback) {
    var that = this;
    var canvas = document.createElement("canvas");

    var width = image.width;
    var height = image.height;

    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }

    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);
    /*
        converting tob loc and back seems to greatly decrease file size
      */
    canvas.toBlob(
      (blob) => {
        // Handle the compressed image. es. upload or save in local state
        that.blobToDataURL(blob, function (res) {
          return callback(
            res.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
          );
        });
      },
      "image/jpeg",
      quality
    );
  }

  resizeImageFile(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("No file provided");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const dataUrl = event.target.result;

        const image = new Image();
        image.src = dataUrl;
        image.onload = () => {
          this.resizeImage(
            image,
            maxWidth,
            maxHeight,
            0.5,
            (resizedDataUrl) => {
              resolve(resizedDataUrl);
            }
          );
        };
        image.onerror = (error) => {
          reject("Image loading failed: " + error);
        };
      };
      reader.onerror = (error) => {
        reject("FileReader failed: " + error);
      };
    });
  }

  async submitImage() {
    this.setState({
      loading: true,
    });

    const formToken = this.props.match.params.formToken;
    const finalPic = this.state.pictures[0];

    try {
      const resizedDataUrl = await this.resizeImageFile(finalPic, 800, 800);
      const restOperation = amplifyPost({
        apiName: "images",
        path: "/images",
        options: {
          body: {
            token: formToken,
            content: resizedDataUrl,
            contentType: finalPic.type,
          },
        },
      });

    const { statusCode } = await restOperation.response;
    if (statusCode !== 200) {
      throw new Error("Failed to submit image");
    }

    window.sessionStorage.setItem("submit", true);

    this.setState({
      loading: false,
      completed: true,
      success: true,
    });

      console.log("POST call succeeded");
    } catch (e) {
      console.error("POST call failed: ", e);
      this.setState({
        loading: false,
        completed: true,
        success: false,
      });
    }
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  render() {
    const Spacer = styled.div`
      width: ${(props) => props.width || 0};
      height: ${(props) => props.height || 0};
    `;

    return (
      <div className="uploadBody">
        {this.state.access ? (
          <>
            {this.state.loading ? (
              <div className="pulseLoader">
                <Spacer
                  height="10px"
                  style={{ width: "45%", textAlign: "center" }}
                />
                <PulseLoader
                  style={{ width: "100%", textAlign: "center" }}
                  color={"#ffffff"}
                  size={10}
                />
              </div>
            ) : this.state.completed ? (
              this.state.success ? (
                <div className="responseHeaders">
                  <div className="responseSubDiv">
                    <p className="pstyle">
                      Your image was received! You can now close this window.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="col col-sm-12 container">
                  <div className="responseHeaders">
                    <div className="responseSubDiv">
                      <p className="pstyle">
                        We ran into a problem receiving your image. Please go
                        ahead and try again.{" "}
                        {this.state.error ? this.state.error : ""}
                      </p>
                    </div>
                  </div>
                  <ImageUploader
                    className="imageUploader col col-sm-12"
                    withIcon={true}
                    buttonText="Choose Image"
                    onChange={this.onDrop}
                    withPreview={true}
                    singleImage={true}
                    imgExtension={[".jpg", ".png", ".jpeg"]}
                    label="Max file size: 5mb"
                    maxFileSize={5242880}
                  />
                  <div
                    style={{
                      display:
                        this.state.pictures.length > 0 ? "block" : "none",
                    }}
                    className="uploadButtonContainer"
                  >
                    <button
                      onClick={this.submitImage}
                      className="btn submitImage"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="col col-sm-12 container">
                <ImageUploader
                  className="imageUploader col col-sm-12"
                  withIcon={true}
                  buttonText="Choose Image"
                  onChange={this.onDrop}
                  withPreview={true}
                  singleImage={true}
                  imgExtension={[".jpg", ".png", ".jpeg"]}
                  label="Max file size: 5mb"
                  maxFileSize={5242880}
                />
                <div
                  style={{
                    display: this.state.pictures.length > 0 ? "block" : "none",
                  }}
                  className="uploadButtonContainer"
                >
                  <button onClick={this.submitImage} className="uploadButton">
                    Upload
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="responseHeaders">
            <div className="responseSubDiv">
              <p className="pstyle">Access Denied</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Upload;

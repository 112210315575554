import React from 'react';
import ReactDOM from 'react-dom';
import Root from "./components/Root";
import configureStore from "./store/store";

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';


document.addEventListener("DOMContentLoaded", () => {

  let store;

  store = configureStore();

  const root = document.getElementById("root");

  ReactDOM.render(<Root store={store} />, root);
});

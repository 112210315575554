import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Page Base
import UploadContainer from './pages/upload/upload_container';

const App = () => (
  <div className="appWrap">
      <Switch>
        <Route exact path="/:formToken"   component={UploadContainer} />
      </Switch>
  </div>
);

export default App;
